import styled from 'styled-components';

export const LinkList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  flex-wrap: wrap;
  max-width: 84rem;
  justify-content: space-between;
  margin: 5rem auto 5rem auto;
`;
