import { Icon, IconDefinition } from '@telia/styleguide';
import React from 'react';
import styled from 'styled-components';
import { media } from '../utils/media';

const PageHeader = styled.div`
  text-align: center;
  max-width: 80rem;
  margin: 2.5rem auto auto auto;
`;

const PageLogo = styled.div`
  position: relative;
  display: inline-block;

  svg {
    position: absolute;
    display: block;
    top: 1rem;
    left: 2.3rem;
    color: white;
    height: 2.25rem;
    width: 2.25rem;

    @media screen and ${media.tablet} {
      height: 4.3rem;
      width: 4.3rem;
      top: .65rem;
      left: 2.6rem;
    }
  }
`;

const Blub = styled.img`
  max-height: 4.5rem;

  @media screen and ${media.tablet} {
    max-height: 6rem;
  }
`;

const Heading = styled.h1`
  font-family: pebble, arial, sans-serif;
  font-size: 2rem;
  line-height: 2.5rem;
  text-align: center;
  margin: 1rem auto;
  color: #570580;

  @media screen and ${media.tablet} {
    font-size: 4rem;
    line-height: 4.5rem;
  }
`;

const SubHeading = styled.div`
  font-size: 1rem;
  line-height: 1.25rem;
  color: #570580;

  @media screen and ${media.tablet} {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }
`;

export const Header = ({ title, icon, subHeading }: { title: string, icon: IconDefinition, subHeading?: string }) => {
  return (
    <PageHeader>
      <PageLogo>
        <Blub src='/images/blub.svg' />
        <Icon icon={icon}/>
      </PageLogo>
      <Heading>{title}</Heading>
      {subHeading && <SubHeading>{subHeading}</SubHeading>}
    </PageHeader>
  );
};
