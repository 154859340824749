/* eslint-disable react/require-default-props */
import React from 'react';
import Helmet from 'react-helmet';
import config from '../../config/SiteConfig';
import Post from '../models/Post';

interface SEOPost {
  postNode?: Post;
  postPath?: string;
}

interface SEO extends SEOPost {
  isPost: boolean;
  titlePrefix?: string;
}

export const SEO = (props: SEO) => {
  const { isPost, postNode, postPath, titlePrefix } = props;
  let title;
  let description;
  let image;
  let postURL;
  const _titlePrefix = titlePrefix ? titlePrefix : '';
  const realPrefix = config.pathPrefix === '/' ? '' : config.pathPrefix;

  if (isPost && postNode && postPath) {
    const postMeta = postNode.frontmatter;
    title = _titlePrefix + postMeta.title;
    description = postNode.excerpt;
    image = config.siteBanner;
    postURL = [config.siteUrl, realPrefix, 'blog', `${postPath}/`].filter(p => !!p).join('/');
  } else {
    title = _titlePrefix + config.siteTitle;
    description = config.siteDescription;
    image = config.siteBanner;
  }
  image = config.siteUrl + realPrefix + image;
  const blogURL = config.siteUrl + config.pathPrefix;
  let schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      '@id': blogURL,
      url: blogURL,
      name: title,
      alternateName: config.siteTitleAlt ? config.siteTitleAlt : '',
    },
  ];
  if (isPost && postNode) {
    schemaOrgJSONLD = [
      {
        '@context': 'http://schema.org',
        '@type': 'BlogPosting',
        // @ts-ignore
        '@id': postURL,
        // @ts-ignore
        url: postURL,
        name: title,
        alternateName: config.siteTitleAlt ? config.siteTitleAlt : '',
        headline: title,
        image: {
          '@type': 'ImageObject',
          url: image,
        },
        description: config.siteDescription,
        datePublished: postNode.frontmatter.date,
        dateModified: postNode.frontmatter.date,
        author: {
          '@type': 'Person',
          name: config.author,
        },
        publisher: {
          '@type': 'Organization',
          name: config.author,
          logo: {
            '@type': 'ImageObject',
            url: config.siteUrl + realPrefix + config.siteLogo,
          },
        },
        isPartOf: blogURL,
        mainEntityOfPage: {
          '@type': 'WebSite',
          '@id': blogURL,
        },
      },
    ];
  }
  return (
    <Helmet>
      {/* General tags */}
      <html lang={config.siteLanguage}/>
      <title>{title}</title>
      <meta name='description' content={description}/>
      <meta name='image' content={image}/>
      <link rel='canonical' href={isPost ? postURL : blogURL}/>

      {/* Schema.org tags */}
      <script type='application/ld+json'>
        {JSON.stringify(schemaOrgJSONLD)}
      </script>

      {/* OpenGraph tags */}
      <meta property='og:locale' content={config.ogLanguage}/>
      <meta property='og:site_name' content={config.ogSiteName ? config.ogSiteName : ''}/>
      <meta property='og:url' content={isPost ? postURL : blogURL}/>
      {isPost ? <meta property='og:type' content='article'/> : null}
      <meta property='og:title' content={title}/>
      <meta property='og:description' content={description}/>
      <meta property='og:image' content={image}/>
      {config.siteFBAppID ? <meta property='fb:app_id' content={config.siteFBAppID}/> : null}

      {/* Twitter Card tags */}
      <meta name='twitter:card' content='summary_large_image'/>
      <meta name='twitter:creator' content={config.userTwitter ? config.userTwitter : ''}/>
      <meta name='twitter:title' content={title}/>
      <meta name='twitter:url' content={config.siteUrl}/>
      <meta name='twitter:description' content={description}/>
      <meta name='twitter:image' content={image}/>
    </Helmet>
  );
};
