// @ts-ignore
import { PageFooter } from '@telia/styleguide';
import AllIcons from '@telia/styleguide/assets/allicons/AllIcons.js';
import { Link } from 'gatsby';
import React from 'react';
import Helmet from 'react-helmet';
import styled, { ThemeProvider } from 'styled-components';
import config from '../../config/SiteConfig';
import theme from '../../config/Theme';
import './layout.scss';

const links = [
  { text: 'Open Source', url: '/open-source' },
  { text: 'Blog', url: '/blog' },
  { text: 'Podcast', url: '/podcasts' },
  { text: 'Careers', url: 'https://www.teliacompany.com/en/careers/' },
];

const specialLink = { text: 'Personvern og Cookies', url: 'https://www.telia.no/personvern/' };

const Wrapper = styled.div`
  padding: 0 1rem;
  margin: 0;
`;

const PageHeader = styled.header`
  display: flex;
  flex-wrap: no-wrap;
  flex-direction: row;
  justify-content: space-between;
  margin: 2rem auto auto auto;
  max-width: 84rem;
  padding: 0;
  align-items: center;
`;

const Logo = styled.img`
  height: 2rem;
  padding: 0;
  margin: 0;
`;

const LinkList = styled.ul`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  justify-content: right;
  list-style: none;

  li {
    padding: 0 0 0 2rem;
    font-size: 1rem;
    line-height: 1.25rem;

    a {
      text-decoration: none;
    }
  }
`;

const Menu = ({}) => {
  return (
    <PageHeader>
      <a href='/'><Logo src='/images/logo.svg' /></a>
      <nav>
        <LinkList>
          {links.map(({ text, url }, idx) => (
            <li key={idx}>
              <a href={url}>
                {text}
              </a>
            </li>
          ))}
        </LinkList>
      </nav>
    </PageHeader>
  );
};

export const Layout = ({ children }: { children: React.ReactChild[] }) => {
  return (
    <ThemeProvider theme={theme}>
      {!config.isProd ? (
        <Helmet>
          <meta name='robots' content='noindex,nofollow' />
        </Helmet>
      ) : null}
      <AllIcons />
      <Wrapper>
        <Menu />
        {children}
      </Wrapper>
      <PageFooter links={links} specialLink={specialLink} />
    </ThemeProvider>
  );
};
