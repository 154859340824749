import { Icon } from '@telia/styleguide';
import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { PageItem } from '../utils/contentList';
import { media } from '../utils/media';

const ItemWrapper = styled.article`
  flex: 3 1 25rem;
  padding: 0 2.125rem 2rem 2.125rem;

  @media screen and ${media.desktop} {
    flex: 0 1 25rem;
  }
`;

const ItemImage = styled.img`
  width: 100%;
`;

const ItemTitle = styled.h2`
  margin: 1.5rem 0 0.75rem 0;
  padding: 0;
  font-size: 1.5rem;
  line-height: 1.75rem;

  a {
    text-decoration: none;
  }
`;

const ItemTagLine = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  justify-content: left;
  align-items: center;
  margin-bottom: 1rem;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 1rem;
  }

  span {
    font-size .9rem;
    line-height: 1.5rem;
    color: #4E4E4E;
  }
`;

const ItemExcerpt = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: break-spaces;
  font-size: 1rem;
  line-height: 1.5rem;
`;

export const Item = ({ title, image, url, timeToRead, icon, excerpt, date, type }: PageItem) => {
  const LinkTag = type === 'blog' ? Link : 'a';
  return (
    <ItemWrapper>
      <header>
        <LinkTag to={url} href={url}>
          <ItemImage src={image} />
        </LinkTag>
        <ItemTitle>
          <LinkTag to={url} href={url}>{type === 'repo' ? 'Repository: ' : ''}{title}</LinkTag>
        </ItemTitle>
        <ItemTagLine>
          <Icon icon={icon} />
          <span>
            {type === 'repo' ? 'Updated' : 'Posted'} {date} {timeToRead && `— ${timeToRead} min ${type === 'blog' ? 'read' : 'listen'}`}
          </span>
        </ItemTagLine>
      </header>
      <ItemExcerpt>{excerpt}</ItemExcerpt>
    </ItemWrapper>
  );
};
